import React, { useState, useEffect } from 'react';
import { useStatePersist } from 'use-state-persist';
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';
import fetchJsonp from 'fetch-jsonp';

import { useCookies } from 'react-cookie';

require('es6-promise').polyfill();

let prevCount = 0;
let currentCount = 0;

export default function SavedJobsListPage(props) {
  const [vacancySavedLoading, setVacancySavedLoading] = useState(true);

  const [vacancySaved, setVacancySaved] = useState([]);
  const [vacancySavedTitle, setVacancySavedTitle] = useState(
    'Loading saved vacancies...'
  );
  const [initialLoading, setInitialLoading] = useState(true);
  const [iconClicked, setIconClicked] = useState(false);

  const [vacsCount, setVacsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadMoreText, setLoadMoreText] = useState(false);
  const [cookies, setCookie] = useCookies(['ap-signals-user-id']);

  const [viewBySelection, setViewBySelection] = useStatePersist(
    '@sortView',
    'grid'
  );

  const [savedJobs, setSavedJobs] = useStatePersist(
    '@savedJobs_g6SDL6CeOWEOBExxiGnCQCpts407QWdgdmR5BqykIAo',
    []
  );

  function unSaveJob(id) {
    const newSavedJobsArray = savedJobs.filter((e) => e !== id.toString());
    setSavedJobs(newSavedJobsArray);
  }

  function checkJobHasExpired(JSONDate) {
    if (JSONDate) {
      let expDate = new Date(parseInt(JSONDate.substr(6), 10));
      let now = new Date();

      if (expDate > now) {
        return false;
      }
    }
    return true;
  }

  const fetchVacancies = async (
    state,
    name,
    e,
    loadMore,
    postingTypeSelection
  ) => {
    // State of filters, Name of searched filter, event, load more boolean
    setLoadMoreText('Loading your saved jobs...');

    if (!loadMore) {
      prevCount = 0;
      currentCount = 0;
    }

    function getAdvertData(jobs, prevCount, currentCount) {
      return Promise.all(
        jobs.slice(prevCount, currentCount).map(async (id) => {
          return fetchJsonp(
            'https://sf.vacancy-filler.co.uk/CareerPage/GetItem?id=' + id,
            {
              timeout: 15000,
            }
          )
            .then(function(response) {
              return response.json();
            })
            .catch(function(error) {
              console.error(error);
              unSaveJob(id);
            });
        })
      );
    }

    try {
      prevCount = currentCount;
      currentCount = currentCount + 12;

      await getAdvertData(savedJobs, prevCount, currentCount).then(function(
        data
      ) {
        data.forEach((job) => {
          if (checkJobHasExpired(job.ExpiryDate)) {
            unSaveJob(job.Id);
            return;
          }
        });

        if (loadMore) {
          setVacancySaved(vacancySaved.concat(data));
        } else {
          setVacancySaved(data);
        }

        if (savedJobs.length === 0) {
          setVacancySavedTitle("We couldn't find any saved jobs");
        } else if (savedJobs.length === 1) {
          setVacancySavedTitle('You have ' + savedJobs.length + ' saved job');
        } else {
          setVacancySavedTitle('You have ' + savedJobs.length + ' saved jobs');
        }

        setInitialLoading(false);

        setLoading(false);
        setVacancySavedLoading(false);

        setLoadMoreText('Load More');
        setVacsCount(savedJobs.length);
      });
    } catch (error) {
      setLoading(false);
      setVacancySavedLoading(false);
      console.error(error.message, 'error');
    }
  };

  const loadMore = function loadMore() {
    fetchVacancies({}, undefined, undefined, true);
  };

  useEffect(() => {
    fetchVacancies({}, undefined, undefined, false);
  }, [savedJobs]);

  const calculateSalary = function calculateSalary(
    Currency,
    SalaryFrom,
    SalaryTo,
    SalaryType,
    SalaryPeriod
  ) {
    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    let NewSalary;
    let NewSalaryFrom;
    let NewSalaryTo;

    let currencyFormatted = Currency;

    if (Currency === '&pound;') {
      currencyFormatted = '£';
    }

    if (SalaryTo === 0) {
      NewSalary = SalaryType;
    } else if (SalaryFrom === SalaryTo) {
      if (SalaryFrom >= 1000) {
        // Remove decimals
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed());
      } else {
        // Add two decimals
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2));
      }
      NewSalary = NewSalaryFrom + ' ' + SalaryPeriod;
    } else {
      if (SalaryFrom >= 1000) {
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed());
      } else {
        NewSalaryFrom =
          currencyFormatted + numberWithCommas(SalaryFrom.toFixed(2));
      }
      if (SalaryTo >= 1000) {
        NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed());
      } else {
        NewSalaryTo = currencyFormatted + numberWithCommas(SalaryTo.toFixed(2));
      }

      NewSalary = NewSalaryFrom + ' - ' + NewSalaryTo + ' ' + SalaryPeriod;
    }
    return NewSalary;
  };

  const getSummary = function getSummary(summary, description) {
    if (summary) {
      return summary;
    } else {
      let alteredDescription = description.replace(/<[^>]*>?/gm, '');
      let tempSummary = alteredDescription.substring(0, 397);
      if (tempSummary.length === 397) {
        tempSummary += '...';
      }
      return tempSummary;
    }
  };

  const getImageFromRegionCards = function getImageFromRegionCards(region) {
    let jobCardRegionNoSpaces = region.replace(/[, ]+/g, '-');

    return jobCardRegionNoSpaces;
  };

  const FormatDateTime = function FormatDateTime(JSONDate) {
    // Set default values to avoid function breaking
    var expDate = new Date(parseInt(JSONDate.substr(6)));
    var dateFormatOptions = new Object({});
    dateFormatOptions.weekday = 'long';
    dateFormatOptions.year = 'numeric';
    dateFormatOptions.month = 'short';
    dateFormatOptions.day = 'numeric';
    return expDate.toLocaleDateString(undefined, dateFormatOptions);
  };

  const slugify = function slugify(text) {
    return text
      .toString() // Cast to string
      .toLowerCase() // Convert the string to lowercase letters

      .trim() // Remove whitespace from both sides of a string
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-'); // Replace multiple - with single -
  };

  return (
    <Layout {...props}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vacancies at Progress Housing Group </title>
        <meta
          name="description"
          content="Find your new career at Progress Housing Group  today"
        />
      </Helmet>

      <div className="home">
        <section className="app-content">
          <div>
            {!vacancySavedLoading && (
              <div className="app-hero-wrapper app-hero-home app-hero-video">
                <div
                  className="app-hero-background-image"
                  style={{
                    backgroundImage: `url(https://cdn1.accropress.com/ProgressGroupHousingCareers/heros/Progress+_17_09_190136+Digital+Services.JPG)`,
                  }}
                ></div>
                <div className="app-hero-background-overlay"></div>
                <div className="app-hero-content-center"></div>
                <div className="app-hero-content-bottom"></div>
              </div>
            )}

            <div
              className={vacancySavedLoading ? 'vacancies-landing-loading' : ''}
            >
              {!vacancySavedLoading ? (
                <section className={`main-vacs-wrap`}>
                  <div>
                    <div className="container">
                      <div className="row clearfix">
                        <div className="col-12 column job-vacancies-col p-0">
                          <h1 className="vacancies-landing__header__heading">
                            {vacancySavedTitle}
                          </h1>
                          <div className="vf-careers-site">
                            <div id="VF_vacancies">
                              <div className="row VF_vacancies_main_wrap">
                                <div className="col-12 p-0">
                                  <div className="main-section">
                                    <div className="row search-container-wrap">
                                      <div className="col-12 inner-content grid-section box-theme-2 joblist-section">
                                        <div
                                          id="VF-vacancies-wrapper"
                                          className="VF_vacancies_list"
                                        >
                                          <div className="row">
                                            {loading ? (
                                              'Loading vacancies...'
                                            ) : (
                                              <>
                                                {vacancySaved &&
                                                vacancySaved !== [] &&
                                                vacancySaved !== '[]' &&
                                                !initialLoading ? (
                                                  <>
                                                    {vacancySaved &&
                                                    vacancySaved.length > 0 ? (
                                                      vacancySaved.map(
                                                        (job, i) => (
                                                          <div
                                                            key={i}
                                                            className="col-12 col-4-m vacancy-card-wrap"
                                                          >
                                                            <div className="vacancy vacancy-card vacancy-card-v2">
                                                              <div className="row">
                                                                <div className="col-12 vacancy-main-details">
                                                                  <div className="vacancy-main-details-inner">
                                                                    <h3 className="job-title">
                                                                      {
                                                                        job.JobTitle
                                                                      }
                                                                    </h3>

                                                                    <div className="job-info-container row">
                                                                      <div className="vacancy-details-wrap col-12">
                                                                        <strong>
                                                                          Salary:{' '}
                                                                        </strong>
                                                                        <span>
                                                                          {calculateSalary(
                                                                            job.Currency,
                                                                            job.SalaryFrom,
                                                                            job.SalaryTo,
                                                                            job.SalaryType,
                                                                            job.SalaryPeriod
                                                                          )}
                                                                        </span>
                                                                      </div>
                                                                      <div className="vacancy-details-wrap col-12">
                                                                        <strong>
                                                                          Closing
                                                                          Date:{' '}
                                                                        </strong>
                                                                        <span>
                                                                          {FormatDateTime(
                                                                            job.ExpiryDate
                                                                          )}
                                                                        </span>
                                                                      </div>
                                                                      <div className="vacancy-details-wrap col-12">
                                                                        <strong>
                                                                          Location:{' '}
                                                                        </strong>
                                                                        <span>
                                                                          {
                                                                            job.Location
                                                                          }
                                                                        </span>
                                                                      </div>
                                                                      <div className="vacancy-details-wrap col-12">
                                                                        <strong>
                                                                          Region:{' '}
                                                                        </strong>
                                                                        <span>
                                                                          {
                                                                            job.Region
                                                                          }
                                                                        </span>
                                                                      </div>
                                                                      <div className="vacancy-details-wrap col-12">
                                                                        <strong>
                                                                          Job
                                                                          Type:{' '}
                                                                        </strong>
                                                                        <span>
                                                                          {
                                                                            job.JobType
                                                                          }
                                                                        </span>
                                                                      </div>
                                                                      <div className="vacancy-details-wrap col-12">
                                                                        <strong>
                                                                          Reference
                                                                          No:{' '}
                                                                        </strong>
                                                                        <span>
                                                                          {
                                                                            job.AdvertReference
                                                                          }
                                                                        </span>
                                                                      </div>
                                                                    </div>

                                                                    <div className="job-summary">
                                                                      {getSummary(
                                                                        job.Summary,
                                                                        job.Description
                                                                      )}
                                                                    </div>
                                                                    <div className="extra content">
                                                                      <div className="ui two buttons">
                                                                        <a
                                                                          className="btn btn-primary"
                                                                          href={
                                                                            '/vacancies/vacancy/' +
                                                                            new Buffer(
                                                                              job.Id
                                                                            ).toString(
                                                                              'base64'
                                                                            ) +
                                                                            '/' +
                                                                            slugify(
                                                                              job.JobTitle
                                                                            ) +
                                                                            '?company=' +
                                                                            encodeURIComponent(
                                                                              getImageFromRegionCards(
                                                                                job.Region
                                                                              )
                                                                            )
                                                                          }
                                                                        >
                                                                          More
                                                                          info
                                                                        </a>
                                                                        <button
                                                                          className="btn btn-link"
                                                                          onClick={() =>
                                                                            unSaveJob(
                                                                              job.Id
                                                                            )
                                                                          }
                                                                        >
                                                                          <span className="far fa-heart">
                                                                            Un
                                                                            save
                                                                          </span>
                                                                        </button>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                {/* <div className="col-12 col-xs-12 vacancy-secondary-details">
                                                                    <div className="vacancy-brand-image-block">
                                                                      <img
                                                                        src={
                                                                          "https://cdn1.accropress.com/ProgressGroupHousingCareers/cards/" +
                                                                          getImageFromRegionCards(
                                                                            job.Region
                                                                          ) +
                                                                          ".png"
                                                                        }
                                                                        alt={job.Region}
                                                                        onError={({ currentTarget }) => {
                                                                          currentTarget.onerror = null; // prevents looping
                                                                          currentTarget.src =
                                                                            'https://cdn1.accropress.com/ProgressGroupHousingCareers/logos/progress-default.png';
                                                                        }}
                                                                      />
                                                                    </div>
                                                                  </div> */}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        )
                                                      )
                                                    ) : (
                                                      <div className="job-card card job-card-no-results">
                                                        <div className="job-card-details">
                                                          <div className="no-results">
                                                            Click the save button
                                                            on the Job Cards to
                                                            add adverts to your
                                                            Saved Jobs.
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </>
                                                ) : (
                                                  <div className="no-vacancies">
                                                    Loading vacancies...
                                                  </div>
                                                )}

                                                <div className="load-more-wrap col-12">
                                                  {currentCount < vacsCount && (
                                                    <a
                                                      className="nav-action-sm"
                                                      role="button"
                                                      onClick={() => loadMore()}
                                                    >
                                                      {loadMoreText}
                                                    </a>
                                                  )}
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              ) : (
                <div className="page-loading-block">
                  <div className="loading-panel">
                    <div className="loading-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <span className="loading-text">
                      Please wait while we load the vacancies available...
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {vacancySavedLoading && (
            <div className="app-content-content app-content-content-loading"></div>
          )}
        </section>
      </div>
    </Layout>
  );
}
