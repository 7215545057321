import React from "react"
import { Router } from "@reach/router"

import SmartFeedIndividual from "../modules/SmartFeedIndividual"
import SmartFeedList from "../modules/SmartFeedList"
import SmartFeedNotFound from "../modules/SmartFeedNotFound"
import SavedJobsListPage from "../components/SavedJobsList"


const Vacancies = () => {
  return (
    <Router>
      <SmartFeedNotFound default />
      <SmartFeedList path="/vacancies" />
      <SavedJobsListPage  path="/vacancies/saved" />
      <SmartFeedIndividual path="/vacancies/vacancy/:id" />
      <SmartFeedIndividual path="/vacancies/vacancy/:id/:vacancyTitlePath" />
    </Router>
  )
}

export default Vacancies
